<!--
 * @Description: 
 * @Author: fely
 * @Date: 2021-01-30 15:03:52
 * @LastEditTime: 2021-02-02 17:23:45
 * @LastEditors: fely
-->
<template>
  <div class="wrapper">
    <div class="header">
      <top-header></top-header>
      <div class="text-con">
        <h1>亿慧小秘书</h1>
        <span>www.gjyxms.com</span>
      </div>
    </div>
    <div class="content-contain">
      <div class="intro-wrapper">
        <div class="text-box">
          <p class="big-title">天天面试个人端</p>
          <p class="small-title">PERSONAL INT</p>
          <p class="detail">求职者根据自己的需求筛选出相关的职位</p>
        </div>
        <div class="image-box">
          <img src="~@/assets/images/position1.png" />
        </div>
      </div>
      <div class="intro-wrapper" style="margin-top: 20px">
        <div class="image-box">
          <img src="~@/assets/images/position2.png" />
        </div>
        <div class="text-box">
          <p class="big-title">职位详情</p>
          <p class="small-title">JOB DETAILS</p>
          <p class="detail">点击某一职位查看职位详情</p>
        </div>
      </div>
      <div class="intro-wrapper" style="margin-top: 20px">
        <div class="text-box">
          <p class="big-title">在线直聊</p>
          <p class="small-title">ONLINE CHAT</p>
          <p class="detail">
            点击职位详情页下方的“在线直聊”按钮，以在线聊天的方式方便快捷的与招聘者沟通职位信息。
          </p>
        </div>
        <div class="image-box">
          <img src="~@/assets/images/position3.png" />
        </div>
      </div>
      <div class="intro-wrapper" style="margin-top: 20px">
        <div class="image-box">
          <img src="~@/assets/images/position4.png" />
        </div>
        <div class="text-box">
          <p class="big-title">天天面试企业端</p>
          <p class="small-title">BUSINESS INTERVIEW</p>
          <p class="detail">求职者根据自己的需求筛选出相关的职位</p>
        </div>
      </div>
      <div class="intro-wrapper" style="margin-top: 20px">
        <div class="text-box">
          <p class="big-title">职位详情</p>
          <p class="small-title">JOB DETAILS</p>
          <p class="detail">点击某一职位查看职位详情</p>
        </div>
        <div class="image-box">
          <img src="~@/assets/images/position5.png" />
        </div>
      </div>
      <div class="intro-wrapper" style="margin-top: 20px">
        <div class="image-box">
          <img src="~@/assets/images/position6.png" />
        </div>
        <div class="text-box">
          <p class="big-title">在线直聊</p>
          <p class="small-title">ONLINE CHAT</p>
          <p class="detail">
            点击职位详情页下方的“在线直聊”按钮，以在线聊天的方式方便快捷的与招聘者沟通职位信息。
          </p>
        </div>
      </div>
      <div class="footer con-foot">
        <p>
          ©天津市亿慧网络科技有限公司 版权所有
          <a class="beian" target="_black" href="http://beian.miit.gov.cn"
            >津ICP备20006513号</a
          >
        </p>
        <div style="margin-left: 20px">
          <a
            target="_blank"
            href="http://www.beian.gov.cn/"
            style="
              display: inline-block;
              text-decoration: none;
              height: 20px;
              line-height: 20px;
            "
          >
            <img src="~@/assets/images/police.png" style="float: left" />
            <p
              style="
                float: left;
                height: 20px;
                line-height: 20px;
                margin: 0px 0px 0px 5px;
                color: #939393;
              "
            >
              津公网安备 12011102001059号
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "./topHeader";
export default {
  name: "",
  data() {
    return {};
  },
  components: {
    TopHeader,
  },
};
</script>

<style scoped lang="less">
@import "../styles/public.css";
@import "../styles/common.css";
.wrapper {
  height: 100%;
}
</style>
